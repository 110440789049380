import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class TravelSuppressCoverage extends Model {
	static entity = 'travelSuppressCoverage';
	static primaryKey = 'locale';

	static async getTravelSuppressedCoverageDescription(username, token, locale) {
		// api call to get the drug
		try {
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/suppressed-coverages/travel`;
			let response = await axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: 'Bearer ' + token
					}
				})
				.catch(() => {
					this.store().dispatch('error');
				});
			return response.data;
		} catch (err) {
			this.store().dispatch('error');
		}
	}
}
