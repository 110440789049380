<template>
	<CoverageTemplate :page-title="$t('title')" full-width>
		<BRow v-if="!suppressCoverage">
			<BCol md="8" sm="12">
				<BaseCard>
					<BCardText>
						{{ $t('body.firstBody.part1') }} <br />
						<br />
						{{ $t('body.firstBody.part2') }}
					</BCardText>
					<TravelBookletDownload
						:description="$t('booklet.info')"
						:link-content="$t('booklet.button')"
						automation-id="travel-coverage"
					></TravelBookletDownload>
				</BaseCard>
				<BaseCard :title="$t('body.secondBody.subtitle')">
					<BCardText
						>{{ $t('body.secondBody.part1') }} <br />
						<br />
						{{ $t('body.secondBody.part2') }}</BCardText
					>
				</BaseCard>
				<BaseCard :title="$t('body.thirdBody.subtitle')">
					<BCardText>
						<p class="sub-text">
							{{ $t('body.thirdBody.subText') }}
						</p>
						<ul>
							<li>{{ $t('body.thirdBody.id') }}</li>
							<li>{{ $t('body.thirdBody.name') }}</li>
							<li>{{ $t('body.thirdBody.birthdate') }}</li>
							<li>{{ $t('body.thirdBody.address') }}</li>
							<li>{{ $t('body.thirdBody.contact') }}</li>
							<li>{{ $t('body.thirdBody.healthCard') }}</li>
							<li>{{ $t('body.thirdBody.currentMedicalSituation') }}</li>
							<li>{{ $t('body.thirdBody.accessToCoverage') }}</li>
						</ul>
						{{ $t('body.thirdBody.bottomBody') }}</BCardText
					>
				</BaseCard>
			</BCol>
			<BCol md="4" sm="12"
				><BaseCard>
					<BCardText
						><p class="title-contact">{{ $t('body.help.getHelp') }}</p>
						<p class="help-time">{{ $t('body.help.time') }}</p>
						<p class="mb-2 h5">{{ $t('body.help.canadaUsa') }}</p>
						<PhoneNumberDisplay class="phone" :phone-number="contactDetails.canadaUsa" />
						<p class="mb-2 h5">{{ $t('body.help.elsewhere') }}</p>
						<PhoneNumberDisplay class="phone-bottom" :phone-number="contactDetails.otherLocation" />
					</BCardText> </BaseCard
			></BCol>
		</BRow>
		<BRow v-if="suppressCoverage">
			<BCol v-if="suppressCoverageWording.length > 0" md="8" sm="12">
				<BaseCard>
					<BCardText
						v-for="(text, index) in suppressCoverageWording"
						:key="index"
						:class="suppressParagraphClass(index)"
					>
						{{ text }}
					</BCardText>
				</BaseCard>
			</BCol>
			<BCol md="4" sm="12"
				><BaseCard>
					<BCardText
						><p class="title-contact">{{ $t('body.help.getHelp') }}</p>
						<p class="help-time">{{ $t('body.help.time') }}</p>
						<p class="mb-2 h5">{{ $t('body.help.canadaUsa') }}</p>
						<PhoneNumberDisplay class="phone" :phone-number="contactDetails.canadaUsa" />
						<p class="mb-2 h5">{{ $t('body.help.elsewhere') }}</p>
						<PhoneNumberDisplay class="phone-bottom" :phone-number="contactDetails.otherLocation" />
					</BCardText> </BaseCard
			></BCol>
		</BRow>
	</CoverageTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BCardText, BCol, BRow } from 'bootstrap-vue';
//components
import CoverageTemplate from './CoverageTemplate.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import PhoneNumberDisplay from '@/components/common/PhoneNumberDisplay';
import TravelBookletDownload from '@/components/coverage/travel/TravelBookletDownload.vue';
//mixnis
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
//routes
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
import { formatPhoneNumber } from '@/utils/commonUtils.js';
import MemberCardInfo from '@/models/MemberCardInfo';
import TravelSuppressCoverage from '@/models/coverage/TravelSuppressCoverage';

@Component({
	name: 'TravelPage',
	components: {
		CoverageTemplate,
		BCol,
		BRow,
		BaseCard,
		BCardText,
		PhoneNumberDisplay,
		TravelBookletDownload
	},
	mixins: [IdMixin, BreadcrumbsManager],

	watch: {
		locale() {
			this.$store.dispatch('updateLoading', true);
			this.getResourceUrls();
			this.getSuppressCoverageDescription();
			this.$store.dispatch('updateLoading', false);
		}
	}
})
export default class TravelPage extends Vue {
	/**
	 * This computed property is used for the watch to know when to call
	 * the updateTabs method.
	 */
	get locale() {
		return this.$store.state.i18n.locale;
	}

	contactDetails = { canadaUsa: '', otherLocation: '' };
	securityPolicy = sessionStorage.getItem('securityPolicy');
	suppressCoverage = false;
	suppressCoverageWording = [];
	async created() {
		this.$store.dispatch('updateLoading', true);
		this.suppressCoverage = JSON.parse(this.securityPolicy).suppressCoverage;
		this.getResourceUrls();
		this.getSuppressCoverageDescription();
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.travel', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.travel', 'fr') }
			]
		);
		this.$store.dispatch('updateLoading', false);
	}

	suppressParagraphClass(index) {
		if (index === this.suppressCoverageWording?.length - 1) {
			return '';
		}
		return 'suppress-paragraph';
	}

	getResourceUrls() {
		MemberCardInfo.getResourceUrlsByEmail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		).then((response) => {
			if (response && (response.status === 201 || response.status === 200)) {
				let secureUrl = response.data.json_travelContactUrl.replace('http://', 'https://');
				fetch(secureUrl)
					.then((response) => response.json())
					.then((data) => {
						this.contactDetails.canadaUsa = formatPhoneNumber(data.canadaUsa);
						this.contactDetails.otherLocation = formatPhoneNumber(data.collect);
					})
					.catch((error) => {
						if (error) {
							// eslint-disable-next-line no-console
							console.log(error);
						}
					});
			} else {
				this.contactDetails.canadaUsa = this.$t('body.help.contactCanadaUsa');
				this.contactDetails.otherLocation = this.$t('body.help.elsewhereContact');
			}
		});
	}

	async getSuppressCoverageDescription() {
		if (this.suppressCoverage) {
			this.suppressCoverageWording =
				await TravelSuppressCoverage.getTravelSuppressedCoverageDescription(
					sessionStorage.getItem('email'),
					sessionStorage.getItem('apiToken'),
					this.$root.$i18n.locale
				);
		}
	}
}
</script>
<style lang="scss" scoped>
.sub-text {
	font-family: $body-regular-bold-font-family;
	font-weight: 400;
}
.title-contact {
	font-family: $body-regular-bold-font-family;
	font-weight: bold;
	margin-bottom: 7px;
}
.phone {
	font-family: $josefin-font-family;
	font-size: 16px;
	margin-bottom: 20px;
}
.phone-bottom {
	font-family: $josefin-font-family;
	font-size: 16px;
}
.help-time {
	margin-bottom: 20px;
}
.suppress-paragraph {
	margin-bottom: 20px;
}
</style>
<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "myCoverage": "Plan Coverage",
			"travel": "Travel"
    },
		"title": "Travel Assistance",
		"booklet": {
			"info": "For complete details on your travel benefits, what we cover and how much we'll cover, please download your benefits booklet.",
			"button": "Benefits Booklet (PDF)"
			},
		"body": {
			"firstBody": {
				"part1": "We provide travel assistance to ensure you have access to services and assistance when you need it. Through the toll-free number, your travel assistance will help you arrange care, payment to hospitals and health professionals and directions to the most appropriate clinic or hospital.",
				"part2": "Travel assistance also provides necessary confirmation of your coverage to health professionals and can arrange for a family member to join you in the event of a medical emergency. Beyond medical needs, your travel assistance can also ensure transmittal of urgent messages along with coordinating claims and interpretation."
			},
			"secondBody": {
				"subtitle": "In case of emergency",
				"part1": "If a medical emergency occurs during travel, please call (or, if necessary, have someone else call) the travel assistance phone number above so they can direct you to a preferred health care provider. If possible, call BEFORE consulting for medical care.",
				"part2": "If your service has been authorized when you call, and the provider is demanding up-front payment or a deposit, please advise your travel assistance representative. In most cases, direct payment to the provider can be arranged."
			},
			"thirdBody": {
				"subtitle": "What to expect when you call travel assistance",
				"subText": "On your first call with CanAssistance, you will be asked for the following:",
				"id": "Blue Cross ID card numbers",
				"name": "Your name and employer's name",
				"birthdate":"Your birthdate",
				"address":"Your home address and travel address",
				"contact":"A contact number",
				"healthCard":"Your provincial health card number",
				"currentMedicalSituation":"Details of your current medical situation",
				"accessToCoverage":"Information on whether you have access to other forms of coverage",
				"bottomBody": "If someone is calling for you, they will be asked to provide the above information on your behalf. Depending on the situation, other questions may be asked. A file number will be given, and needs to be noted and quoted each time you call."
			},
			"help":{
				"getHelp":"Get help courtesy of CanAssistance",
				"time":"24 hours a day, 7 days a week",
				"canadaUsa":"Canada and U.S.A.",
				"contactCanadaUsa":"1-800-563-4444",
				"elsewhere":"Elsewhere in the world (call collect)",
				"elsewhereContact":"1-506-854-2222"
			}
		}
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "myCoverage": "Couverture du régime",
			"travel": "Voyage"
    },
		"title": "Assistance voyage",
		"booklet": {
			"info": "Pour connaître tous les détails de votre assurance voyage — ce qui est couvert et le montant qui est couvert —, veuillez télécharger votre brochure de garanties.",
			"button": "Brochure de garanties (PDF)"
			},
			"body": {
			"firstBody": {
				"part1": "Nous offrons une Assistance voyage afin de vous garantir l’accès aux services et au soutien nécessaire, au moment où vous en avez besoin. Par l’entremise du numéro sans frais, le service d’Assistance voyage vous aidera à obtenir des soins, à payer les hôpitaux et les professionnels de la santé et à trouver la clinique ou l’hôpital pouvant le mieux répondre à vos besoins.",
				"part2": "Les services d’Assistance voyage comprennent également la confirmation de couverture demandée par les professionnels de la santé et la prise de dispositions pour qu’un membre de votre famille vous rejoigne en cas d’urgence médicale. Au-delà des besoins médicaux, le service d’Assistance voyage peut transmettre les messages urgents et coordonner les demandes de règlement et les services d’interprétation."
			},
			"secondBody": {
				"subtitle": "En cas d’urgence",
				"part1": "Si une urgence médicale survient pendant un voyage, téléphonez (ou demandez à quelqu’un de téléphoner, si nécessaire) au numéro d’Assistance voyage ci-dessus; un intervenant vous dirigera vers le fournisseur de soins de santé approprié. Si possible, téléphonez AVANT toute consultation pour soins de santé.",
				"part2": "Si votre service est autorisé au moment de votre appel, et que le fournisseur demande un paiement complet préalable ou un dépôt, avisez votre représentant ou représentante de l’Assistance voyage. Dans la plupart des cas, un paiement direct au fournisseur peut être effectué."
			},
			"thirdBody": {
				"subtitle": "Ce qui vous attend lorsque vous téléphonez à l’Assistance voyage",
				"subText": "Lors de votre premier appel avec CanAssistance, on vous demandera les informations suivantes:",
				"id": "Vos numéros d’identification de Croix Bleue",
				"name": "Votre nom et le nom de votre employeur",
				"birthdate":"Votre date de naissance",
				"address":"L’adresse de votre domicile et votre adresse pendant le voyage",
				"contact":"Un numéro pour vous joindre",
				"healthCard":"Le numéro de votre carte d’assurance maladie provinciale",
				"currentMedicalSituation":"Les détails de votre situation médicale actuelle",
				"accessToCoverage":"De l’information sur les autres assurances auxquelles vous avez accès, le cas échéant",
				"bottomBody": "Si une personne téléphone à votre place, elle devra donner ces renseignements en votre nom. Selon la situation, d’autres questions pourraient s’ajouter. Nous vous donnerons un numéro de dossier; vous devez le noter et le citer à chaque appel."
			},
			"help":{
				"getHelp":"Obtenez l’aide de CanAssistance",
				"time":"24 heures sur 24, 7 jours par semaine",
				"canadaUsa":"Canada et États-Unis",
				"contactCanadaUsa":"1-800-563-4444",
				"elsewhere":"Ailleurs dans le monde (à frais virés)",
				"elsewhereContact":"1-506-854-2222"
			}
		}
  }
}
</i18n>
