<!-- 
  Component with download link to get a member's benefits booklet in PDF format.
-->
<template>
	<div v-if="bookletId !== null">
		<p>
			<strong>{{ description }}</strong>
		</p>
		<BaseButton
			:label="linkContent"
			variant="primary"
			:automation-id="getAutomationId('booklet-download')"
			@click="downloadBooklet()"
		/>
		<ErrorModal
			:error-title="$t('modal.title')"
			:error-message="$t('modal.message')"
			:has-close-button="false"
			:modal-show="showBookletError"
			:automation-id="getAutomationId('bookletErrorModal')"
			@change="showBookletError = false"
		>
			<BaseButton
				class="mt-2"
				:label="$t('button.ok')"
				:aria-label="$t('button.ok')"
				variant="primary"
				:automation-id="getAutomationId('bookletErrorCloseButton')"
				@click="showBookletError = false"
			/>
		</ErrorModal>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';
import BenefitsBooklet from '@/models/coverage/BenefitsBooklet.js';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
export default {
	name: 'TravelBookletDownload',
	components: {
		ErrorModal,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		description: {
			type: String,
			required: true
		},
		linkContent: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			showBookletError: false,
			bookletId: null
		};
	},

	async mounted() {
		try {
			this.bookletId = await BenefitsBooklet.getBenefitsBookletId();
		} catch (e) {
			// this will show the booklet links but show error on fetching
			this.bookletId = null;
		}
	},

	methods: {
		async downloadBooklet() {
			// if there was an error retreiving the booklet id, show the error modal
			if (!this.bookletId) {
				this.showBookletError = true;
			} else {
				const success = await BenefitsBooklet.getBenefitsBooklet();

				if (!success) {
					this.showBookletError = true;
					this.bookletId = null;
				}
			}
		}
	}
};
</script>

<i18n>
{
  "en": {
    "button": {
      "ok": "Ok"
    },
    "modal": {
      "title": "We aren’t able to access your benefits booklet right now.",
      "message": "Give us a call and we’ll get that figured out."
    }
  },
  "fr": {
     "button": {
      "ok": "OK"
    },
    "modal": {
      "title": "Nous ne sommes pas en mesure d’accéder à votre brochure de garanties pour l’instant.",
      "message": "Appelez-nous et nous trouverons une solution au problème."
    }
  }
}
</i18n>
